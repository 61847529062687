import {Link, navigate} from 'gatsby';
import * as React from 'react';
import {Button, Container, Form} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import ShawAcademyLogo from '../../../static/images/logo/logo-new.inline.svg';
import {
  getOfferType,
  getParameterByName,
} from '../../Application/Signup/SignupService';
import cookie from 'react-cookies';
import styles from './voucher.module.scss';
import PropTypes from 'prop-types';
import {
  ctaClickAction, pageViewAction,
} from '../../../src/Application/Shared/TrackActionService';

/**
 *
 *
 * @export
 * @class Voucher
 * @extends {Component}
 */
export class Voucher extends React.Component {
  /**
   * Creates an instance of Voucher.
   * @param {*} props
   * @memberof Voucher
   */
  constructor(props) {
    super(props);
    this.state = {
      voucher: '',
      utmSource: null,
      errors: {
        voucher: false,
      },
    };
    this.voucherInputRef=React.createRef(null);
  }

  /**
   *
   *
   * @memberof Voucher
   */
  componentDidMount() {
    this.voucherInputRef?.current?.focus();
    const locale = this.props?.location?.state?.locale || 'en';
    pageViewAction('VoucherPage', locale);
    const utmSource = getParameterByName('utm_source') ||
      cookie.load('utm_source') || '';
    this.setState({
      utmSource,
    });
  }

  /**
   *
   *
   * @param {*} e
   * @memberof Voucher
   */
  handleChange = (e) => {
    const {name, value} = e.target;
    if (name === 'voucher') {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: !this.checkVoucherValidation(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }
  /**
   *
   * @return {*}
   * @memberof Voucher
   */
  getSelectedCourseId = () => {
    // Fetching courseId from query param or cookie
    const course = getParameterByName('courseId') ||
      cookie.load('coursePicked') ||
      '';
    return course;
  }
  /**
   *
   *
   * @memberof Voucher
   */
  handleContinue = () => {
    ctaClickAction({
      'studentId': cookie.load('shawacademy_leadId'),
      'cta': 'Continue',
      'action': 'RedeemVoucher',
    });
    const utmMedium = getParameterByName('utm_medium') ||
      cookie.load('utm_medium') || '';
    const utmCampaign = getParameterByName('utm_campaign') ||
      cookie.load('utm_campaign') || '';
    const offerType = getOfferType(this.state.utmSource,
        utmMedium, utmCampaign);
    if (offerType === 'CC') {
      const paymentUrl = `${process.env.PaymentWebDomain
      }/payment/subscription/?product_code=sc-1m-ft`;
      setTimeout(() => {
        window.location.href = paymentUrl;
      }, 1000);
    } else {
      const selectedCourse = this.getSelectedCourseId();
      const locale = this.props?.location?.state?.locale || 'en';
      const nextUrl=`/app/${locale}/register?course_slug=${selectedCourse}`;
      navigate(nextUrl);
    }
  }
  /**
   *
   * @return  {*}
   * @param {*} value
   * @memberof Voucher
   */
  checkVoucherValidation = (value) => {
    if (value?.length > 3) {
      return true;
    }
    return false;
  }
  /**
   *
   *
   * @param {*} field
   * @param {*} value
   * @memberof Voucher
   */
  setValidation = (field, value) => {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: !value,
      },
    }));
  }
  /**
   *
   *
   * @param {*} e
   * @memberof Voucher
   */
  formValidation = (e) => {
    const {name, value} = e.target;
    switch (name) {
      case 'voucher': this.setValidation(name,
          this.checkVoucherValidation(value));
        break;
    }
  }
  /**
   *
   *
   * @return {*}
   * @memberof Voucher
   */
  render() {
    const {voucher, errors, utmSource} = this.state;
    return (
      <Container className={styles.voucherForm}>
        <Helmet>
          <meta name="robots" content="nofollow, noindex" />
        </Helmet>
        <Helmet>
          <script>
          window.dataLayer=window.dataLayer||[];
          </script>
        </Helmet>
        <Helmet>
          <script async src="/js/cookieconsent-scripts.min.js"></script>
        </Helmet>
        <header>
          <div className={styles.innerWrapper}>
            <Link to="/">
              <ShawAcademyLogo />
            </Link>
            {
              utmSource &&
              <img
                className={styles.partnerLogo}
                onError={(i) => i.target.style.display='none'}
                src={`https://partner-dashboard.shawacademy.com/uploads/${utmSource}.png`}
                alt='partner logo'
              />
            }
          </div>
        </header>
        <Container className={styles.shFormContainer}>
          <Form className={styles.shForm} noValidate>
            <div className={styles.formHeadings}>
              <p className={styles.heading}>Unlock Reward</p>
              <p className={styles.subheading}>
              Enter the Voucher code to claim your reward
              </p>
            </div>
            <Form.Group controlId="formBasicPhone"
            >
              <Form.Label>Voucher Code</Form.Label>
              <Form.Control
                type="text"
                name="voucher"
                value={voucher}
                ref={this.voucherInputRef}
                placeholder={'XXX-XXX-XXXX'}
                onChange={this.handleChange}
                onBlur={this.formValidation}
                className={voucher && (!errors.voucher ?
                  styles.valid : styles.invalid)}
                autoComplete="off"
              />
              {errors.voucher &&
              <div className={styles.errorWrapper}>
                Please enter correct voucher code
              </div>}
            </Form.Group>
            <Button
              onClick={this.handleContinue}
              className={`${styles.shBtn} ${!errors.voucher && voucher ?
                styles.validated : ''}`}>
          Continue
            </Button>
          </Form>
        </Container>
      </Container>
    );
  }
}

export default Voucher;

Voucher.propTypes = {
  location: PropTypes.object,
};
